/* Swimming animation */
@keyframes float {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
}

@keyframes wave {
  0% { d: path('M0 50 Q 25 40, 50 50 T 100 50 T 150 50 T 200 50'); }
  50% { d: path('M0 50 Q 25 60, 50 50 T 100 50 T 150 50 T 200 50'); }
  100% { d: path('M0 50 Q 25 40, 50 50 T 100 50 T 150 50 T 200 50'); }
}

@keyframes swim {
  0% { transform: translate(0, 0); }
  50% { transform: translate(100px, 0); }
  100% { transform: translate(0, 0); }
}

/* Cycling animation */
@keyframes wheel {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pedal {
  0% { transform: translate(0, -5px); }
  50% { transform: translate(0, 5px); }
  100% { transform: translate(0, -5px); }
}

/* Running animation */
@keyframes run {
  0% { transform: translate(-50px, 0); }
  50% { transform: translate(50px, -20px); }
  100% { transform: translate(-50px, 0); }
}

@keyframes bounce {
  0%, 100% { transform: scaleY(1); }
  50% { transform: scaleY(0.8); }
}

/* Apply animations */
.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-wave {
  animation: wave 2s ease-in-out infinite;
}

.animate-swim {
  animation: swim 4s linear infinite;
}

.animate-wheel {
  animation: wheel 2s linear infinite;
}

.animate-pedal {
  animation: pedal 1s ease-in-out infinite;
}

.animate-run {
  animation: run 2s ease-in-out infinite;
}

.animate-bounce {
  animation: bounce 1s ease-in-out infinite;
}
