@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  color-scheme: dark;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-black text-secondary;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  body {
    @apply bg-black text-secondary;
  }
}

/* Custom background effect */
.dashboard-background {
  background: linear-gradient(to bottom right, 
    rgba(163, 236, 62, 0.03) 0%,
    transparent 30%,
    transparent 70%,
    rgba(163, 236, 62, 0.02) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.glow {
  position: absolute;
  background: radial-gradient(circle at center, rgba(163, 236, 62, 0.03) 0%, transparent 70%);
  border-radius: 50%;
}

.glow-1 {
  width: 600px;
  height: 600px;
  top: -100px;
  left: -100px;
}

.glow-2 {
  width: 400px;
  height: 400px;
  top: 30%;
  right: -50px;
}

@layer components {
  .btn {
    @apply inline-flex items-center justify-center px-6 py-2 rounded-full font-medium transition-all duration-200;
  }

  .btn-primary {
    @apply bg-primary text-black hover:bg-primary/90 hover:shadow-lg hover:shadow-primary/10 active:scale-95;
  }

  .btn-secondary {
    @apply bg-surface text-secondary hover:bg-surface-light hover:shadow-lg hover:shadow-surface-light/10 active:scale-95;
  }

  .form-group {
    @apply space-y-1;
  }

  .label {
    @apply block text-sm font-medium text-secondary;
  }

  .input {
    @apply block w-full rounded-lg border-0 bg-surface-light px-3 py-2 text-secondary shadow-sm ring-1 ring-inset ring-surface-lighter placeholder:text-secondary/50 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6;
  }

  .input[type="date"],
  .input[type="time"] {
    @apply dark:text-secondary dark:bg-surface-light;
  }

  .input[type="date"]::-webkit-calendar-picker-indicator,
  .input[type="time"]::-webkit-calendar-picker-indicator {
    @apply dark:invert;
  }

  select.input {
    @apply bg-surface-light appearance-none pr-8;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
  }

  .nav-link {
    @apply text-secondary/70 hover:text-secondary transition-colors;
  }

  .text-muted {
    @apply text-secondary/60;
  }

  .card {
    @apply bg-surface rounded-xl p-6;
  }

  /* DatePicker Portal Styles */
  html body #root .react-datepicker-portal .react-datepicker {
    @apply !bg-surface !border-surface-light !font-sans !shadow-lg;
    width: min(90vw, 320px) !important;
  }

  html body #root .react-datepicker-portal .react-datepicker * {
    @apply !border-surface-light;
  }

  html body #root .react-datepicker-portal .react-datepicker__header {
    @apply !bg-surface-light !border-surface-light !p-4;
  }

  html body #root .react-datepicker-portal .react-datepicker__current-month {
    @apply !text-secondary !mb-2 !font-semibold;
  }

  html body #root .react-datepicker-portal .react-datepicker__day-names {
    @apply !mt-2;
  }

  html body #root .react-datepicker-portal .react-datepicker__day-name {
    @apply !text-secondary/70 !w-8 !h-8 !leading-8 !m-0;
  }

  html body #root .react-datepicker-portal .react-datepicker__day {
    @apply !text-secondary !w-8 !h-8 !leading-8 !m-0 !rounded-lg;
  }

  html body #root .react-datepicker-portal .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
    @apply !bg-primary/20 !text-primary;
  }

  html body #root .react-datepicker-portal .react-datepicker__day--selected,
  html body #root .react-datepicker-portal .react-datepicker__day--keyboard-selected {
    @apply !bg-primary !text-black !font-medium;
  }

  html body #root .react-datepicker-portal .react-datepicker__day--disabled {
    @apply !text-secondary/30 hover:!bg-transparent;
  }

  html body #root .react-datepicker-portal .react-datepicker__navigation {
    @apply !top-4;
  }

  html body #root .react-datepicker-portal .react-datepicker__navigation-icon::before {
    @apply !border-secondary;
  }

  html body #root .react-datepicker-portal .react-datepicker__navigation:hover .react-datepicker__navigation-icon::before {
    @apply !border-primary;
  }

  html body #root .react-datepicker-portal .react-datepicker__triangle {
    @apply !hidden;
  }

  /* Portal Container */
  html body #root .react-datepicker-portal {
    @apply !fixed !inset-0 !bg-black/80 !flex !items-center !justify-center z-[60];
  }

  /* Month Container */
  html body #root .react-datepicker-portal .react-datepicker__month-container {
    @apply !float-none !w-full;
  }

  html body #root .react-datepicker-portal .react-datepicker__month {
    @apply !m-0 !p-2;
  }

  /* Input Styling */
  html body #root .react-datepicker__input-container input {
    @apply !bg-surface !text-secondary;
  }

  /* Wrapper Width */
  html body #root .react-datepicker-wrapper {
    @apply !w-full;
  }

  /* Calendar Specific Styles */
  .calendar-grid {
    @apply border-t border-l border-surface-light/20;
  }

  .calendar-cell {
    @apply border-b border-r border-surface-light/20;
  }

  @media (max-width: 640px) {
    .react-datepicker__day-name,
    .react-datepicker__day {
      @apply !w-10 !h-10 !leading-10 !text-base !m-0.5;
    }

    .react-datepicker__current-month {
      @apply !text-lg !mb-3;
    }

    .react-datepicker__header {
      @apply !p-3;
    }

    .react-datepicker__month {
      @apply !m-2;
    }
  }

  /* Desktop Calendar Styles */
  @media (min-width: 641px) {
    .calendar-grid {
      @apply !border-t !border-l border-surface-light/20;
    }
    
    .calendar-cell {
      @apply !border-b !border-r border-surface-light/20;
    }
  }

  /* Mobile date picker styles */
  input[type="date"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    color: inherit;
    font: inherit;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid rgb(var(--color-border));
    width: 100%;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(0.5);
    cursor: pointer;
    opacity: 0.6;
  }

  /* Dark mode styles for date picker */
  @media (prefers-color-scheme: dark) {
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }

  /* iOS specific date picker styles */
  @supports (-webkit-touch-callout: none) {
    input[type="date"] {
      min-height: 2.5rem;
      display: flex;
      align-items: center;
    }
  }
}
